.mobileNavigation {
    display: none;
}

@media (max-width: 768px) {
    .navigation {
        display: none;
    }

    .mobileNavigation {
        display: flex;
        align-items: center;
    }

    nav ul {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 80px;
        right: 0;
        width: 100%;
        background-color: #fff9f7;
        border-top: 2px solid #ff5821;
        z-index: 2;
    }

    nav ul li {
        padding-left: 15%;
    }
}